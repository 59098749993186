
/**
* app design #f65282
*/
*{
  color:#eee;
}

.btn-number{
  color:#eee;
}
/* end demo only */
body{
  /* margin-top:20px; */
  overflow: hidden;
}
.mobile-bottom-nav__item {
  flex-grow: 1;
  text-align: center;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* .mobile-bottom-nav__item--active {
  color: white !important;
} */

.mobile-bottom-nav__item-content {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color:#eee;
}
 .mlink{
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 12px;
  width:25%;
 }
.active-nav{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color:white;
}
.content{
    top: 50%;
    left: 50%;
    width: 480px;
    height: 100vh;
    transform: translate(-50%, -50%);
    position: absolute;
    box-sizing: border-box;
    padding: 0;margin:0;
    overflow:hidden;
}

.page-header {
  box-sizing: border-box;
  background-color: #0f0f0f;
  top: 0;
  left:0px;
  z-index:2;
  padding: 16px;
  color:white;
  position: absolute;
  width: 100%;

}
.page-body{
    overflow-y: auto;
    padding-top:80px;
    padding-left:10px;
    padding-right:10px;
    background-color: #2b3035;
    box-sizing: border-box;
    overflow-x: hidden;
    padding-bottom: 90px;

}
.page-body2{
  overflow-y: auto;
  padding-top:60px;
  padding-left:10px;
  padding-right:10px;
  background-color: #2b3035;
  box-sizing: border-box;
  overflow-x: hidden;
  padding-bottom: 80px;
}
  /* window */
  @media only screen and (min-width: 900px) {
      .page-footer {
        background:#0f0f0f;
        width:480px;
        bottom:0;
        padding:0;
        box-sizing: border-box;
        transform: translateZ(0);
        display: flex;
        position: absolute;
        height: 60px;
       
    }
    .mpage-footer{
      overflow: hidden;
      background-color: #0f0f0f;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }
@media only screen and (max-width: 900px) {
  body{
    overflow-y: hidden;
  }
  .content {
      width:100%;
  }
  .page-header{
    top:0;
    left:0;
  }
  .page-body{
    padding-top: 80px;
    margin-bottom: 30px;
    height:100vh;
  }
  .page-body2{
    padding-top: 80px;
    overflow-y: scroll;
  }
   .page-footer{
    overflow: hidden;
    background-color: #0f0f0f;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    height: 60px;
    /* display: flex; */
    /* height: 60px;
    z-index: 1000;
    left:0; */
     /* margin-bottom: 25px;  */
  } 
  .mpage-footer{
    overflow: hidden;
    background-color: #0f0f0f;
    position: fixed;
    bottom: 0;
    width: 100%;
    margin-bottom: 30px;
  }
}
.link{
  text-decoration: none;
  cursor: pointer;
}

.unauthorized-profile-menu,.two-history-card{
-webkit-box-shadow: 0 5px 5px #888888;
-moz-box-shadow: 0 5px 5px #888888;
box-shadow: 0 5px 5px #888888;
border-radius: 15px;
}
.slideshow-img{
-webkit-box-shadow: 0 4px 5px #888888;
-moz-box-shadow: 0 4px 5px #888888;
box-shadow: 0 4px 5px #888888;
}
.modal-backdrop{
width:480px;
height: 100vh;
}
.MuiInputBase-input{
border:1px solid blue;
background-color: white !important;
}
.logo-name{
margin-left:10px;
font-weight:bolder;
font-size:16px;
}
.home-reload{
margin-left:20px;
}
.home-reload:hover{
color:white;
}

.animate-charcter
{
 text-transform: uppercase;
background-image: linear-gradient(
  -225deg,
  #231557 0%,
  #44107a 29%,
  #ff1361 67%,
  #fff800 100%
);
background-size: auto auto;
background-clip: border-box;
background-size: 200% auto;
color: #fff;
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
animation: textclip 2s linear infinite;
display: inline-block;
    font-size: 35px;
}
@keyframes textclip {
to {
  background-position: 200% center;
}
}
.selection-div{
color: grey;
text-decoration: underline;
}